// ***** start - import from files *****
import rootReducer from './rootReducer'
import { configureStore } from '@reduxjs/toolkit'
// ***** end - import from files *****

const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware => {
    return getDefaultMiddleware({
      serializableCheck: false
    })
  }
})

export { store }