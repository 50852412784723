// ***** start - import from files *****
import navbar from './navbar'
import layout from './layout'
import auth from './authentication'
// ***** end - import from files *****

const rootReducer = {
  auth,
  navbar,
  layout
}

export default rootReducer