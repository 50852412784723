// ***** start - import from package *****
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
// ***** end - import from package *****

// ***** start - import from files *****
import en from "./translation/en.json";
import ge from "./translation/ge.json";
// ***** end - import from files *****

// ***** start - resources for translation file *****
const resources = {
  en: {
    translation: en,
  },
  ge: {
    translation: ge,
  },
};
// ***** end - resources for translation file *****

// ***** start - config. of translation file *****
i18n.use(initReactI18next).init({
  resources,
  lng: process.env.REACT_APP_LANGUAGE,
  fallbackLng: process.env.REACT_APP_LANGUAGE,
  debug: false,
  interpolation: {
    escapeValue: false,
  },
});
// ***** end - config. of translation file *****

export default i18n;
